<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Experiment 5: Results - Part C</h2>

      <p class="mb-5">
        a) In this part of the experiment, choose the pair of half-cells that have the closest
        theoretical cell potential that is LESS THAN the target voltage. Fill in the information in
        the data table below summarizing your results from this part of the experiment.
        <b>Please express the percent error value to 3 significant figures.</b>
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\textbf{Variable}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\textbf{Value}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Target}$" />
              <stemble-latex content="$\text{ E}_\text{cell}$" />
              <stemble-latex content="$\text{ (V)}$" />
            </td>
            <td class="py-2 my-2" style="text-align: center">
              <latex-number :number="targetVoltage.toFixed(3)" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{i) V}_\ce{stock}$" />
              <stemble-latex content="$\text{ in Vol}$" />
              <stemble-latex content="$\text{ Flask 1}$" />
              <stemble-latex content="$\text{ (mL)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.volDil1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\ce{ii) [C]}$" />
              <stemble-latex content="$\text{ in Vol}$" />
              <stemble-latex content="$\text{ Flask 1}$" />
              <stemble-latex content="$\text{ (M)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.concFlask1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{iii) V}_\ce{dil}$" />
              <stemble-latex content="$\text{ in Vol}$" />
              <stemble-latex content="$\text{ Flask 2}$" />
              <stemble-latex content="$\text{ (mL)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.volDil2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\ce{iv) [C]}$" />
              <stemble-latex content="$\text{ in Vol}$" />
              <stemble-latex content="$\text{ Flask 2}$" />
              <stemble-latex content="$\text{ (M)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.concFlask2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{v) E}_\text{cell,obs}$" />
              <stemble-latex content="$\text{ (V)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.EcellObs"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{vi) }\%\,\text{Error}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.pctError"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2 mt-5">
        b) Please upload your cell diagram AND your data sheet for this part of the experiment using
        the upload field below.
      </p>

      <p class="mb-3">
        <v-file-input v-model="attachments" multiple accept="application/pdf" />
      </p>

      <p class="mb-1" style="font-weight: bold">
        Have multiple files to upload? Click
        <a
          href="https://support.stemble.ca/attach-multiple-files "
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        to find out how.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import STextarea from '@/common/components/STextareaShort.vue';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcMA5_ResultsC',
  components: {
    STextarea,
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        volDil1: null,
        concFlask1: null,
        volDil2: null,
        concFlask2: null,
        EcellObs: null,
        EcellTheoretical: null,
        pctError: null,
      },
      attachments: [],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id ?? 1;
    },
    // voltageChange() {
    //   return seededRandomBetween(this.seed, 0.02, 0.1, 3);
    // },
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    baseVoltage() {
      if (this.versionNumber.value === 1) {
        return 1.102;
      } else if (this.versionNumber.value === 2) {
        return 0.4775;
      } else {
        return 0.6243;
      }
    },
    targetVoltage() {
      return this.baseVoltage + 0.1;
    },
  },
};
</script>
<style scoped></style>
